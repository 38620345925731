import dispatchEvent from '../utils/dispatch-event';

class RemoveItem extends HTMLElement {
  constructor() {
    super();

    this.button = this.querySelector('[data-remove-button]');
    this.init();
  }

  getVariant() {
    return this.button.dataset.variantid;
  }

  init() {
    this.button.addEventListener('click', event => {
      event.preventDefault();

      fetch(`/cart/update.js?updates[${this.getVariant()}]=0`).then(() => {
        dispatchEvent('cart:quantity-updated', document);
      });
    });
  }
}

customElements.define('remove-item', RemoveItem);
